<template>
  <ion-page
    ><cyan-page-wrapper
      title="Control de Participación"
      backRoute="/4F"
      nav1="PARTICIPACIÓN"
      :nav2="datosCentro.nombre"
      :ambito="ambito"
      class="puntoCyan"
      :iccFlex="true"
    >
      <div class="dummyDiv"></div>
      <div class="mobileShow">
        <div class="banner nivel1">{{ datosCentro.nombre }}</div>
        <div class="banner nivel2">{{ ambito }}</div>
      </div>

      <div class="filtro">
        <ion-searchbar
          id="duisearch"
          ref="duisearch"
          inputmode="numeric"
          type="text"
          v-model="filtroRaw"
          @keyup="checkEnvioFiltro"
          placeholder="DUI"
          :debounce="0"
          :disabled="buscando"
        >
        </ion-searchbar>
      </div>
      <div class="transitionDiv">
        <transition name="fade" mode="out-in">
          <div class="banner nivel1 gris" v-if="buscando">Buscando...</div>

          <div class="banner nivel1 gris" v-else-if="!filtroAplicable">
            Escriba los 9 dígitos del DUI para buscar a un votante asignado a
            este centro.
          </div>

          <div
            class="banner nivel1 gris"
            v-else-if="!movilizado || !movilizado.ok"
          >
            Hubo un error haciendo la búsqueda de este DUI. Favor de intentarlo
            de nuevo.
          </div>

          <div class="banner nivel1 gris" v-else-if="!movilizado.found">
            El DUI introducido no está en el padrón electoral.
            Verifique el valor introducido e inténtelo de nuevo.
          </div>

          <div v-else>
            <transition name="fade" mode="out-in">
              <div
                class="banner nivel1 gris estado"
                v-if="!movilizado.centroOk">
                El votante no pertenece al centro:
              </div>
              <div
                class="banner nivel1 gris estado"
                v-else-if="movilizado.movilizado == 'pending'"
              >
                Enviando...
              </div>
              <div class="banner nivel1 gris estado" v-else-if="movilizado.movilizado">
                Pulse para DESMARCAR asistencia:
              </div>
              <div class="banner nivel1 gris estado" v-else>
                Pulse para MARCAR asistencia:
              </div>
            </transition>

            <div
              :class="['votante', 'em-' + (movilizado.movilizado??'wrong')]"
              @click="movilizadoClick()"
            >
              <ion-row>
                <ion-col size="12" class="nombre">{{
                  movilizado.nombre
                }}</ion-col>
              </ion-row>
              <ion-row v-if="movilizado.centroOk" >
                <ion-col size="12" size-xs class="dato">
                  Participó<br>
                  <span>{{
                    movilizado.movilizado === "pending"
                      ? "..."
                      : movilizado.movilizado
                      ? "Sí"
                      : "No"
                  }}</span>
                </ion-col>
                <ion-col size="12" size-xs class="dato" v-if="movilizado.jrv">
                  JRV<br>
                  <span>{{
                    movilizado.jrv
                  }}</span>
                </ion-col>
                <ion-col size="12" size-xs class="dato">
                  DUI<br>
                  <span>{{ movilizado.dui }}</span>
                </ion-col>
              </ion-row>
              <ion-row v-else >
                <ion-col size="12" class="dato">
                  Centro<br>
                  <span>{{
                    movilizado.nombreCentro
                  }}</span>
                </ion-col>
                <ion-col size="12" size-xs class="dato">
                  Depto.<br>
                  <span>{{
                    movilizado.deptoCentro
                  }}</span>
                </ion-col>
                <ion-col size="12" size-xs class="dato">
                  Distr.<br>
                  <span>{{ movilizado.mpioCentro }}</span>
                </ion-col>
              </ion-row>
            </div>
          </div>
        </transition>
      </div>
      <div class="dummyDiv"></div>
      <div class="dummyDiv"></div> </cyan-page-wrapper
  ></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";

import { territorios } from "@/modules/cyanRegions";
import ud from "@/modules/cyanUserData";
import ValidatorSet, {
  validateDUI,
} from "@/modules/cyanValidator";
import {
  IonRow,
  IonCol,
  IonPage,
  toastController,
  IonSearchbar,
} from "@ionic/vue";

import cyanRequest from "../modules/cyanRequest";

export default defineComponent({
  name: "PuntoCyan",
  components: {
    IonRow,
    IonPage,
    IonCol,
    IonSearchbar,
    CyanPageWrapper,
  },
  setup() {
    return {};
  },
  mounted() {
      this.validator = new ValidatorSet([
      {
        validator: validateDUI,
        element: "#duisearch", // this.$refs.duisearch as any,
      }]);
  },
  data() {
    return {
      filtroRaw: "",
      movilizado: { ok: false },
      buscando: false,
      validator: null as any
    };
  },
  computed: {
    filtro(): string {
      const f = this.filtroRaw.replace(/[^0-9]/g, "");
      if (f.length < 9) return f;
      return f.substr(0, 8) + "-" + f.substr(8, 1);
    },
    nivel(): string {
      return (this.$route.params.ambito as string) || "";
    },
    acj() {
      const n = this.nivel as string;
      const acj = store.state.allowedCentrosJuntas[n];
      return acj || {};
    },
    idCentro(): string {
      return (this.$route.params.centro as string) || "-1";
    },

    filtroAplicable() {
      return (this as any).filtro.length == 10;
    },

    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in territorios)) return "";
      return territorios[n].nombre + ", " + territorios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },
    prefix() {
      if (store.getters.userHasPermission("pcsl")) return 'pcsl/';
      else return 'dgsl/';
    }
  },
  watch: {
    filtroRaw: function () {
      if (this.filtroRaw != this.filtro) {
        this.filtroRaw = this.filtro;
        const el = (this.$refs.duisearch || document.getElementById('duisearch')) as any;

        if (el) {
          el.value = this.filtro; // bug en ion-input
          if (
            el.children &&
            el.children[0] &&
            el.children[0].tagName == "INPUT"
          )
            el.children[0].value = this.filtro;
        }
      }
    },
    filtro: function () {
      const _t = this as any;
      if (_t.filtroAplicable) {
        _t.buscando = true;

        cyanRequest(this.prefix + "checkDUI/" + this.filtro, {
          isPost: true,
          silent: true,
          needsAuth: true,
          retries: 3,
        })
          .catch(function () {
            return { ok: false };
          })
          .then(function (r) {
            _t.movilizado = r;
            _t.buscando = false;
          });
      }
    },
  },
  methods: {
    movilizadoClick() {
      const m = this.movilizado as any;

      if (!m.ok || !m.found || !m.centroOk) return;
      if (m.movilizado === "pending") return;

      const oldEM = m.movilizado;
      m.movilizado = "pending";

      return cyanRequest(
        this.prefix + (oldEM ? "unsetDUI" : "setDUI") + "/" + m.dui,
        {
          isPost: true,
          needsAuth: true,
          retries: 3,
        }
      ).then(function (d: any) {
        let msg = m.nombre + " (DUI " + m.dui + ").";

        if (d.ok) {
          if (m && m.ok && m.dui && m.dui == d.dui) m.movilizado = d.movilizado;
          msg =
            "Se " +
            (d.movilizado ? "MARCÓ" : "DESMARCÓ") +
            " como participante a " +
            msg;
        } else {
          m.movilizado = oldEM;
          msg =
            "Hubo un error al actualizar el estado de " +
            msg +
            " Inténtelo de nuevo más tarde.";
        }

        toastController
          .create({
            message: msg,
            duration: 2000,
          })
          .then((toast: any) => toast.present());
        return d;
      });
    },
    async checkEnvioFiltro(e: KeyboardEvent) {
      if (
        e.keyCode != 13 ||
        this.filtro === "" ||
        !this.movilizado ||
        !this.movilizado.ok ||
        !(this.movilizado as any).found
      )
        return;

      const r = await this.movilizadoClick();
      if (r.ok) this.filtroRaw = "";
    },
  },
});
</script>

<style scoped>
.nombre {
  font-weight: 500;
  font-size: 200%;
  color: white;
  text-align: center;
  margin-bottom: 0.5em;
  margin-top: 0.2em;
}
.dato {
  color: var(--cyan-dark-grey);
  font-weight: 500;
/*  padding-left: 1.6em;
  text-indent: -1.6em;*/
  text-align: center;
}
.dato span {
  color: white;
  font-weight: normal;
}
.votante {
  margin: 5px 0;
  border-radius: 10px;
  background: var(--ion-color-primary);
  color: white;
  padding: 10px 15px;
}

.em-true {
  background: rgb(10, 199, 10);
  cursor: pointer;
}

.em-wrong {
  background: rgb(199, 10, 10);
  cursor: pointer;
}


.em-false {
  /*  background: rgb(253, 225, 225);*/
  cursor: pointer;
}

.em-pending {
  background: rgb(178, 178, 191);
}


.dato ion-icon {
  font-size: 110%;
  padding: 0 0 0 3px;
  position: relative;
  top: 3px;
}

.fade-leave {
  opacity: 1;
}

.fade-leave-active {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.fade-enter-active {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}

.transitionDiv {
  min-height: 10em;
}
.estado {
  min-height: 80px;
}

</style>